import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { WrapRootElement } from '../templates/styles'

import { device } from '../device'
import { spacers, palette } from '../styles/theme'
import Layout from '../components/layout'

const PostArticle = styled.article`
  margin: 0;
  padding: 0;
  max-width: 1204px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const PostHeader = styled.header`
  margin: 0;
  margin-top: ${spacers.spacer6};
  margin-bottom: ${spacers.spacer5};
  padding: 0;
`

const PostTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Bold';
  font-size: 1.875rem;
  line-height: 1.5;
  color: ${palette.color6};
  @media ${device.intermediate} {
    font-size: 2.25rem;
  }
  @media ${device.web} {
  }
`

const MessagePage = ({ location, title, message }) => {
  return (
    <Layout isBlog={false} location={location} title="THM Studio">
      <PostArticle>
        <PostHeader>
          <PostTitle>{title}</PostTitle>
        </PostHeader>
        <WrapRootElement>
          <div id="innerHtml">
            <p>{message}</p>
          </div>
        </WrapRootElement>
      </PostArticle>
    </Layout>
  )
}

MessagePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default MessagePage
